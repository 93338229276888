export default [
  // {
  //   key: '0', name: '看板管理', icon: 'bar-chart', submenus: [
  //     { key: '/warehouse_kanban', name: '仓库综合看板' },
  //     // { key: '/receipt_kanban', name: '收货单据看板' },
  //     // { key: '/picking_kanban', name: '拣货单据看板' },
  //   ]
  // },
  {
    key: "1",
    name: "报表",
    icon: "bar-chart",
    submenus: [
      { key: "/inventory_report", name: "库存明细" },
      { key: "/material_inventory_report", name: "库存报表" },
      { key: "/stock_in_report", name: "入库报表" },
      { key: "/stock_out_report", name: "出库报表" },
      { key: "/delivery_record_report", name: "复核报表" },
      { key: "/location_flow", name: "库位流水" },
      { key: "/warehouse_layout", name: "库位平面图" },
    ],
  },
  {
    key: "2",
    name: "基础数据",
    icon: "table",
    submenus: [
      { key: "/warehouse", name: "仓库管理" },
      { key: "/reservoir_area", name: "库区管理" },
      { key: "/stock_location", name: "库位管理" },
      { key: "/client", name: "客户管理" },
      { key: "/order_type", name: "订单类型" },
    ],
  },
  {
    key: "3",
    name: "产品信息",
    icon: "appstore",
    submenus: [
      { key: "/categories", name: "产品分类" },
      { key: "/unit", name: "单位管理" },
      { key: "/material", name: "产品信息" },
      { key: "/expiration_warning", name: "临期预警" },
    ],
  },
  {
    key: "4",
    name: "入库管理",
    icon: "import",
    submenus: [
      { key: "/stock_in/receipt_order_list", name: "入库通知单" },
      { key: "/stock_in/receipt_task", name: "收货任务" },
      { key: "/stock_in/shelve_task", name: "上架任务" },
    ],
  },
  {
    key: "5",
    name: "出库管理",
    icon: "export",
    submenus: [
      { key: "/stock_out/delivery_order_list", name: "出库通知单" },
      { key: "/stock_out/picking_task", name: "拣货任务" },
      { key: "/stock_out/delivery_task", name: "复核任务" },
    ],
  },
  {
    key: "6",
    name: "库内管理",
    icon: "database",
    submenus: [
      { key: "/stock_check/stock_check_task", name: "盘点" },
      { key: "/stock_transfer/order_record", name: "调拨" },
      { key: "/location_transfer_record", name: "移库" },
    ],
  },
  {
    key: "charge",
    name: "计费管理",
    icon: "account-book",
    submenus: [
      { key: "/charge/rule", name: "计费规则" },
      { key: "/charge/expense", name: "其他费用" },
      { key: "/charge/report", name: "费用合计" },
    ],
  },
  {
    key: "8",
    name: "系统管理",
    icon: "team",
    submenus: [
      { key: "/role", name: "角色管理" },
      { key: "/account", name: "员工账号" },
      { key: "/order_prefix", name: "单据字头" },
      { key: "/label_configs", name: "标签配置" },
      { key: "/system_configs", name: "系统信息" },
      { key: "/mobile_download", name: "移动端下载" },
    ],
  },
];
